import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index-page',
		component: index
	},
	{
		path: '/thank-you',
		name: 'thank-you',
		// route level code-splitting
		// this generates a separate chunk (thank-you.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "thank-you" */ '../views/thank-you.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
