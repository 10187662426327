<template>
	<div id="mainpage" class="flex flex-col min-h-screen home wood-bg" :style="{backgroundPosition: `center ${winScrollY.woods}px`}">

		<SiteHeader />
		
		<section class="section banner-section relative lg:h-0 lg:min-h-[900px] X__parallax z-[1]">
			<!-- <img class="absolute top-0 left-0 object-cover object-[60%] w-full h-auto pointer-events-none lg:object-top parallax-bg X__-skew-y-6" src="@/assets/img/banner-bg.jpg" alt="" width="756" height="567" :style="{top: `${winScrollY.global}px`}"> -->
			<div class="absolute -top-32 left-0 w-full bottom-0 skew-y-6 X__bg-white pointer-events-none overflow-hidden X__parallax">
				<img class="absolute top-32 left-0 object-cover object-[60%] w-full h-full lg:h-auto pointer-events-none lg:object-top X__parallax-bg -skew-y-6" src="@/assets/img/banner-bg02.jpg" alt="" width="1920" height="1080" :style="{top: `${winScrollY.banner}px`}">
			</div>
			<!-- <div class="X__bg-gradient-to-r bg-[#0008] lg:bg-transparent X__lg:from-[#244D13C0] w-full lg:w-10/12 h-[150%] absolute top-0 left-0 pointer-events-none"></div> -->
			<div class="container relative h-full mt-48 lg:mt-0">
				<div class="flex items-center h-full">
					<div class="flex lg:justify-end">
						<div class="flex flex-wrap w-full lg:w-10/12 relative pb-8 lg:py-12 lg:gap-y-4 lg:justify-end">
							<div class="absolute hidden lg:block inset-0 right-1/2 pointer-events-none bg-gradient-to-l from-[#244D13C0]"></div>
							<div class="absolute hidden lg:block inset-0 left-1/2 pointer-events-none bg-[#E8D716]"></div>
							<div class="flex flex-wrap relative w-full gap-y-4 bg-gradient-to-t from-[#244D13F0] lg:bg-none pt-16 pb-4 lg:py-0 -mt-8 lg:mt-0">
								<div class="w-full lg:w-1/2 px-4 lg:px-8 text-center lg:text-right text-white">
									<div class="text-4xl font-semibold uppercase font-oswald lg:text-6xl lg:leading-tight">
										A Piece of Art <span class="inline-block font-extralight">Under Your Feet.</span>
									</div>
								</div>
								<div class="w-full lg:w-1/2 px-4 lg:px-8 text-center lg:text-left text-white lg:text-[#2B2B2B]">
									<div class="text-4xl font-semibold uppercase font-oswald lg:text-6xl lg:leading-tight">
										<span class="inline-block font-extralight">We Are The Guys</span> <span class="inline-block">You Need!</span>
									</div>
								</div>
							</div>
							<div class="flex flex-wrap relative w-full gap-y-4 bg-[#E8D716] lg:bg-transparent pt-4 pb-6 lg:py-0">
								<div class="w-full lg:w-1/2 px-6 lg:px-8 text-justify lg:text-right text-[#2B2B2B] lg:text-white">
									<p>
										We deliver a complete quality wood works in sash, flooring, ceiling, wall and other interior and architectural accent that is done systematically by an expert.
									</p>
								</div>
								<div class="w-full lg:w-1/2 px-4 lg:px-8 text-left text-[#2B2B2B] space-y-6">
									<ul class="pl-4 list-['✔']">
										<li class="pl-3">Nail-Less Wood Flooring Installation</li>
										<li class="pl-3">Special Sanding for Premium Finish</li>
										<li class="pl-3">Professional Workmanship</li>
									</ul>
									<a href="#inquiry" v-smooth-scroll class="justify-self-center mx-auto lg:mx-0 border border-[#2B2B2B] hover:border-white hover:bg-white duration-200 w-[210px] h-[44px] lg:h-[50px] flex items-center justify-center rounded-full">
										Send Us A Message
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="relative section products-section z-[1]">
			<div class="absolute top-0 left-0 w-full h-full skew-y-6 X__bg-white pointer-events-none">
				<div class="absolute top-0 left-0 right-0 h-36 bg-white"></div>
				<!-- <div class="absolute inset-0 top-16 lg:top-32 bg-[#E8D716] overflow-hidden">
					<div class="absolute inset-0 -skew-y-6 -top-64 -bottom-64 wood-bg" :style="{backgroundPosition: `center ${winScrollY.woods}px`}"></div>
					<div class="absolute inset-0 -skew-y-6 -top-64 -bottom-64 wood-bg-3 mix-blend-darken opacity-20"></div>
				</div> -->
			</div>
			<div class="container relative pb-15 lg:pb-32 lg:-top-12 space-y-8 lg:space-y-12">
				<div class="">
					<iframe class="mx-auto w-full lg:w-9/12 h-auto" style="aspect-ratio:16/9" width="560" height="315" src="https://www.youtube-nocookie.com/embed/c5QtIvTWEHo?si=QRcnSUkLNicgg8_G" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				</div>
				<div class="lg:flex bg-white shadow-md">
					<div class="flex justify-center lg:justify-start items-center text-3xl lg:text-5xl lg:leading-tight text-white bg-[#244D13] font-oswald p-4 text-right">
						<div>
							<span class="lg:block font-extralight">Our</span> Services
						</div>
					</div>
					<div class="flex items-center p-4">
						<ul class="grid-cols-3 list-disc lg:grid">
							<li class="ml-6">Wooden Flooring</li>
							<li class="ml-6">Wooden Doors & Jambs</li>
							<li class="ml-6">Wooden Stairs, Handrails & Balusters</li>
							<li class="ml-6">Wooden Ceiling & Wall Panel</li>
							<li class="ml-6">Narra Parquet </li>
							<li class="ml-6">Solid Wood Planks</li>
							<li class="ml-6">Teakwood Planks</li>
							<li class="ml-6">Sash Works</li>
							<li class="ml-6">Sanding Services</li>
							<li class="ml-6">Varnishing Works</li>
							<!-- <li class="ml-6">Fit-Out Works</li> -->
							<li class="ml-6">Solid Wood Kitchen Cabinets</li>
						</ul>
					</div>
				</div>

				<div class="mt-16 lg:mt-24 space-y-8 lg:space-y-12">
					<div class="text-3xl text-center lg:text-5xl lg:leading-tight font-oswald">
						<span class="font-extralight">Our</span> Products
					</div>
					<!-- <div class="grid gap-8 lg:grid-cols-3">
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-flooring.jpg" alt="" width="420" height="315">
							<div class="font-bold">Wooden Flooring</div>
						</div>
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-stairs.jpg" alt="" width="420" height="315">
							<div class="font-bold">Wooden Stairs</div>
						</div>
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-ceilingwall.jpg" alt="" width="420" height="315">
							<div class="font-bold">Wooden Ceiling & Wall Panels</div>
						</div>
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-door.jpg" alt="" width="420" height="315">
							<div class="font-bold">Wooden Door</div>
						</div>
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-sashworks.jpg" alt="" width="420" height="315">
							<div class="font-bold">Sash Works</div>
						</div>
						<div class="text-center space-y-2">
							<img class="w-full h-auto" src="@/assets/img/product-interiors.jpg" alt="" width="420" height="315">
							<div class="font-bold">Wood Interiors Sanding & Varnishing</div>
						</div>
					</div> -->
					<div class="grid gap-12">
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center">
								<div class="hidden lg:block w-full"></div>
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Wooden Flooring</div>
								<hr class="hidden lg:block w-full border-white border-t-2">
							</div>
							<div class="lg:col-span-2 bg-white p-2 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-flooring04.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-flooring05.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-flooring01.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center lg:order-2">
								<hr class="hidden lg:block w-full border-white border-t-2">
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Wooden Stairs</div>
								<div class="hidden lg:block w-full"></div>
							</div>
							<div class="lg:col-span-2 bg-white p-2 lg:order-1 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-stairs01.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-stairs02.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-stairs03.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center">
								<div class="hidden lg:block w-full"></div>
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Wooden Ceiling<br>and Wall Cladding</div>
								<hr class="hidden lg:block w-full border-white border-t-2">
							</div>
							<div class="lg:col-span-2 bg-white p-2 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-ceilingwall01.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-ceilingwall02.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-ceilingwall03.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center lg:order-2">
								<hr class="hidden lg:block w-full border-white border-t-2">
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Wooden Door</div>
								<div class="hidden lg:block w-full"></div>
							</div>
							<div class="lg:col-span-2 bg-white p-2 lg:order-1 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-door01.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-door02.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-door04.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center">
								<div class="hidden lg:block w-full"></div>
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Wooden Cabinets</div>
								<hr class="hidden lg:block w-full border-white border-t-2">
							</div>
							<div class="lg:col-span-2 bg-white p-2 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-cabinets01.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-cabinets02.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-cabinets03.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>
						<div class="grid gap-y-4 lg:gap-y-8 lg:grid-cols-3">
							<div class="flex items-center justify-center lg:order-2">
								<hr class="hidden lg:block w-full border-white border-t-2">
								<div class="border-white border rounded-full px-4 bg-white py-1 flex-none text-center font-bold text-xl lg:text-2xl">Sash Work</div>
								<div class="hidden lg:block w-full"></div>
							</div>
							<div class="lg:col-span-2 bg-white p-2 lg:order-1 overflow-hidden">
								<owl-carousel
									class=""
									:dots="true"
									:nav="false"
									:loop="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="8"
									:responsive="{
										0: {items: 1},
										1024: {items: 2},
									}"
								>
									<div class="">
										<img class="" src="@/assets/img/product-sashwork01.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-sashwork02.jpg" alt="" width="420" height="315">
									</div>
									<div class="">
										<img class="" src="@/assets/img/product-sashwork03.jpg" alt="" width="420" height="315">
									</div>
								</owl-carousel>
							</div>
						</div>

					</div>
				</div>

				<!-- <div class="space-y-4">
					<img class="mx-auto" src="@/assets/img/icon-car.png" alt="Car icon" width="146" height="64">
					<div class="text-3xl font-bold text-center heading text-siteRed-2">
						Browse our High-Quality Transport Units
					</div>
				</div>
				<div class="grid gap-4 lg:grid-cols-2 lg:gap-8">

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-vios.jpg" alt="Toyota Vios">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Toyota Vios
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">Sedan</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">5 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-innova.jpg" alt="Toyota Innova">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Toyota Innova
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">AUV</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">7 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-montero.jpg" alt="Mitsubishi Montero">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Mitsubishi Montero
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">SUV</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">7 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-grandia.jpg" alt="Toyota Grandia">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Toyota Grandia
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">Van</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">12 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-supergrandia.jpg" alt="Toyota Super Grandia">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Toyota Super Grandia
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">Van</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">10 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400" style="aspect-ratio:2/1">
						<div>
							<img class="object-cover w-full h-full" src="@/assets/img/unit-commuterdeluxe.jpg" alt="Toyota Hi-Ace Commuter Deluxe">
						</div>
						<div class="flex flex-col bg-gray-200">
							<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
								Toyota Hi-Ace Commuter Deluxe
							</div>
							<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
								<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
									<li class="pl-2 marker:font-bold"><span class="text-black">Van</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">15 seats</span></li>
									<li class="pl-2 marker:font-bold"><span class="text-black">M/T</span></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="lg:col-span-2">
						<div class="grid grid-cols-2 border border-gray-400 divide-x divide-gray-400 lg:w-1/2 lg:mx-auto" style="aspect-ratio:2/1">
							<div>
								<img class="object-cover w-full h-full" src="@/assets/img/unit-alphard.jpg" alt="Toyota Alphard">
							</div>
							<div class="flex flex-col bg-gray-200">
								<div class="px-2 py-4 text-lg text-center text-white bg-black lg:text-xl font-oswald">
									Toyota Alphard
								</div>
								<div class="flex items-center h-full p-4 text-lg lg:text-2xl">
									<ul class="pl-4 list-['✔'] mx-auto text-siteRed">
										<li class="pl-2 marker:font-bold"><span class="text-black">Sedan</span></li>
										<li class="pl-2 marker:font-bold"><span class="text-black">5 seats</span></li>
										<li class="pl-2 marker:font-bold"><span class="text-black">A/T</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>

				</div> -->
			</div>
		</section>

		<section class="relative section woods-section z-[1]">
			<!-- <div class="absolute top-0 left-0 w-full h-full skew-y-6 pointer-events-none">
				<div class="absolute inset-0 X__bottom-16 X__lg:bottom-32 X__bg-[#E8D716] overflow-hidden">
					<div class="absolute inset-0 -skew-y-6 -top-64 -bottom-64 wood-bg" :style="{backgroundPosition: `center ${winScrollY.woods}px`}"></div>
				</div>
			</div> -->
			<div class="container relative pb-8 X__lg:pb-48 lg:-top-12">
				<div class="space-y-12">
					<div class="text-3xl text-center lg:text-5xl lg:leading-tight font-oswald">
						<span class="font-extralight">List of</span> Woods Offered
					</div>
					<div class="lg:px-[55px]">
						<div class="shadow-lg">
							<owl-carousel
								class="woods-slider relative max-w-[1138px] bg-white lg:bg-transparent"
								:nav="false"
								:loop="true"
								:autoplay="true"
								:autoHeight="false"
								:autoplayTimeout="5000"
								:touchDrag="true"
								:mouseDrag="true"
								:margin="0"
								:items="1"
								:responsive="{
									0: {dots:true},
									1024: {dots:false},
								}"
							>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-azobe.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Azobe
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Azobe is firm and heavy, being amongst the hardest usable woods in the world, and is largely impervious to the effects of insects and weather. These qualities make it a first-class construction timber with tremendous longevity. However, drying must be done slowly, and at low temperature, to avoid breakage or distortions. Examples of azobe's many uses are bridge construction, the building of railway sleepers and freight cars, and vehicle construction. In addition, it is used for wood decking, heavy lumber beams, and the production of laboratory furniture.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-ipil.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Ipil
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Ipil (Intsia bijuga (Colebr.) Kuntze). Ipil is a lowland rainforest tree that grows up to 45 meters. The tree has prominent buttresses when mature. It is commonly found along the coasts and occasionally, on low hills. Ipil produces one of the most valuable timbers in southeast Asia.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-movingui.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Movingui
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">The exotic species movingui is a lemon yellow to yellow brown wood. It is a medium-heavy, medium-hard to hard wood. It does not require preservative treatment against dry wood borers, however it may need to be treated if there is a risk of temporary moistening.</p>
									</div>
								</div>
								<!-- <div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-narra-amarillo.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Narra Amarillo
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Also called asana, padauk, mukwa, Burmese rosewood, or Andaman redwood, genus of timber trees of the pea family (Fabaceae), native to Asia and Africa. Narra wood is primarily used for cabinetwork; it is usually red or rose colour, often variegated with yellow. The wood is hard and heavy, and the pattern of the grain and the colouring are hardly equaled by any other timber. The name refers especially to Pterocarpus indicus, or India padauk, noted for the ability of its wood to take a high polish.</p>
									</div>
								</div> -->
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-narra-yellowish.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Narra (Rosewood)
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Also called asana, padauk, mukwa, Burmese rosewood, or Andaman redwood, genus of timber trees of the pea family (Fabaceae), native to Asia and Africa. Narra wood is primarily used for cabinetwork; it is usually red or rose colour, often variegated with yellow. The wood is hard and heavy, and the pattern of the grain and the colouring are hardly equaled by any other timber. The name refers especially to Pterocarpus indicus, or India padauk, noted for the ability of its wood to take a high polish.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-oakwood.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Oak Wood
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Oak wood is a type of hardwood that derives from the oak tree native to the northern hemisphere. There are around 600 species of oak, both deciduous and evergreen. North America has the most, with around 90 species being found there. Oak wood has been used as a hardwood timber for thousands of years, yet it takes up to 150 years before the wood from an oak tree can be used for construction. Modern uses for oak wood include homewares, flooring, wine barrels, and firewood.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-okoume.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Okoume
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Okoume is one of the main exotic timber species exploited in the Congo basin. Offering a relatively uniform appearance and tones ranging from light red to red brown, okoume timber is widely used in the manufacture of plywood, and also to make furniture, moulding, and panelling. Okoume timber is a very light to light exotic wood, very soft to soft, with a light red to red-brown appearance that darkens with age.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-pinewood.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Pine Wood
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Pine wood is one of the most ubiquitous and least expensive types of wood available. Because of this, some people hold pine wood in low regard. Pine wood varieties are beautiful and diverse, featuring various colors, textures, and wood grains. It is useful as wood in architectural features, foundational building material, and in the creation of by-products like paper.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-tanguile.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Tanguile
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Tanguile (Shorea negrosensis). A tree with a long and cylindrical buttress bole, the tanguile has a reddish-brown wood that is often called Philippine mahogany among other variants. It is a semi-hardwood that is often used as framing, flooring, furniture, and stairs.</p>
									</div>
								</div>
								<div class="flex flex-wrap bg-white w-full pt-4 px-4 lg:p-8 items-center justify-center gap-y-6">
									<div class="w-1/2 lg:w-1/4">
										<img class="" src="@/assets/img/wood-teakwood.jpg" alt="" width="337" height="600">
									</div>
									<div class="w-full lg:w-3/4 lg:p-8 space-y-4 lg:space-y-8">
										<div class="text-2xl lg:text-4xl uppercase font-medium text-center">
											Teak Wood
										</div>
										<p class="text-justify lg:leading-relaxed text-sm lg:text-base">Teak is a tropical hardwood tree species in the family Lamiaceae. It is a large, deciduous tree that occurs in mixed hardwood forests. Tectona grandis has small, fragrant white flowers arranged in dense clusters at the end of the branches. These flowers contain both types of reproductive organs.</p>
									</div>
								</div>

								<template slot="prev">
									<button class="absolute top-1/2 -translate-y-1/2 left-[-55px] w-[55px] h-64 bg-[#202020] hover:bg-gray-700 duration-200 rounded-tl-full rounded-bl-full hidden lg:flex justify-center items-center text-white text-6xl font-oswald font-extralight pb-4">
										&lt;
									</button>
								</template>
								<template slot="next">
									<button class="absolute top-1/2 -translate-y-1/2 right-[-55px] w-[55px] h-64 bg-[#202020] hover:bg-gray-700 duration-200 rounded-tr-full rounded-br-full hidden lg:flex justify-center items-center text-white text-6xl font-oswald font-extralight pb-4">
										&gt;
									</button>
								</template>
							</owl-carousel>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="relative section about-section z-[1]">
			<div class="absolute top-0 left-0 w-full h-full skew-y-6 pointer-events-none">
				<div class="absolute inset-0 bg-[#244D13] overflow-hidden">
					<!-- <div class="absolute inset-0 -skew-y-6 -top-64 -bottom-64 wood-bg" :style="{backgroundPosition: `center ${winScrollY.woods}px`}"></div> -->
				</div>
			</div>
			<div class="container relative py-16 lg:pb-32">
				<div class="flex gap-8 items-end">
					<div class="flex-1 hidden lg:block">
						<img class="rounded-full" src="@/assets/img/about-img.jpg" alt="" width="960" height="960">
					</div>
					<div class="space-y-8 lg:space-y-12 text-white w-full lg:w-8/12">
						<div class="text-center lg:text-left text-3xl lg:text-5xl lg:leading-tight font-oswald">
							About <span class="font-extralight">Us</span>
						</div>
						<div class="space-y-4 text-justify">
							<p>M.V. Samera Enterprises is a family business headed by Engr. Michael Viola Samera. He founded the company in 2001 out from the learnings and experiences he got from the small wood work business owned by his father.</p>
							<p>M.V. Samera delivers a complete quality service of wooden sash and flooring works that are done systematically by an expert. With over 20 years of expertise, we are fostering the culture of what <strong>MVS</strong> stands for: <strong>Members with Teamwork, Victory belongs to God, and Service with Integrity</strong>. These are the essential core values that motivate our actions excellently while performing the key activities of our company. Employees are motivated that “work is born out of love”, and “work is the manifestation of love and is directed toward love”.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="relative section about-section z-[1]">
			<div class="absolute top-0 left-0 w-full h-full skew-y-6 pointer-events-none">
				<div class="absolute inset-0 overflow-hidden">
					<div class="absolute inset-0 -skew-y-6 -top-64 -bottom-64 wood-bg" :style="{backgroundPosition: `center ${winScrollY.woods}px`}"></div>
					<div class="absolute inset-0 bottom-[50%] lg:bottom-0 lg:right-[50%] bg-[#E8D71680] X__backdrop-blur-sm"></div>
					<div class="absolute inset-0 top-[50%] lg:top-0 lg:left-[50%] bg-[#20202080] X__backdrop-blur-sm"></div>
				</div>
			</div>
			<div class="container relative pt-8 pb-12 lg:pt-16 lg:pb-32">
				<div class="grid lg:grid-cols-2 gap-x-16 space-y-16">
					<div class="space-y-8 lg:space-y-12">
						<div class="text-3xl lg:text-5xl lg:leading-tight font-oswald">
							Vision
						</div>
						<div class="space-y-4 text-justify">
							<p>To become a company with <strong>culture of excellence</strong> that offers high quality of workmanship. To become one of the most preferred wood sash and flooring contractor in the Philippines.</p>
						</div>
					</div>
					<div class="space-y-8 lg:space-y-12 text-white">
						<div class="text-3xl lg:text-5xl lg:leading-tight font-oswald">
							Mission
						</div>
						<div class="space-y-4 text-justify">
							<p>We aim to deliver high quality products and services as par to international standard, anchored to our core values and culture that seeks to give the client the best possible product.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="relative section contact-section z-0 bg-white">
			<div class="absolute top-0 left-0 right-0 h-36 skew-y-6 bg-white pointer-events-none">
				<!-- <div class="absolute top-0 left-0 right-0 h-36 bg-white"></div> -->
			</div>
			<div class="container relative py-8 lg:py-16">
				<div class="grid lg:grid-cols-2 gap-x-16 space-y-16">
					<div class="space-y-12">
						<div class="text-3xl lg:text-5xl lg:leading-tight font-oswald">
							Contact <span class="font-extralight">Us</span>
						</div>
						<div class="space-y-4">
							<div class="space-y-2">
								<div class="font-bold text-xl">Local Address</div>
								<p>16 Velasquez St., Sitio Bangiad, San Juan, Taytay, Rizal</p>
							</div>
							<div class="space-y-2">
								<div class="font-bold text-xl">Mobile</div>
								<div>
									<a class="inline-block hover:underline" href="tel:+639171230651">(+63) 917 123 0651</a>
								</div>
								<div>
									<a class="inline-block hover:underline" href="tel:+639209080357">(+63) 920 908 0357</a>
								</div>
							</div>
							<div class="space-y-2">
								<div class="font-bold text-xl">Email</div>
								<a class="inline-block hover:underline" href="mailto:mvsamera.pqc@gmail.com">mvsamera.pqc@gmail.com</a>
							</div>
							<div class="space-x-8 !mt-8">
								<a class="inline-block hover:brightness-150 duration-200" href="https://www.facebook.com/profile.php?id=100090286620317&mibextid=ZbWKwL" target="_blank" rel="nofollow">
									<img src="@/assets/img/icon-fb.png" alt="" width="45" height="45">
								</a>
								<a class="inline-block hover:brightness-150 duration-200" href="https://www.instagram.com/mvsameraenterprises01/?fbclid=IwAR3l1VbZa5_aUphAUyfCPdNHHcfc1Z2aA2kmrHScwgLnI7QUlzXlAPrw_BA" target="_blank" rel="nofollow">
									<img src="@/assets/img/icon-ig.png" alt="" width="45" height="45">
								</a>
								<a class="inline-block hover:brightness-150 duration-200" href="https://twitter.com/MVSamera01?fbclid=IwAR3dr7cLD-8IVhBiXAJDRsP0NX9g7lqSzXjTT9g1BumFPyTgWaT7BjpgS5U" target="_blank" rel="nofollow">
									<img src="@/assets/img/icon-tw.png" alt="" width="45" height="45">
								</a>
							</div>
						</div>
					</div>

					<div id="inquiry" class="space-y-12">
						<ContactForm />
					</div>
				</div>
			</div>
		</section>

		<!-- <section class="section about-section odd:bg-white even:bg-gray-200">
			<div class="container space-y-12 py-15">
				<div class="text-3xl font-bold text-center heading text-siteRed-2">
					Company Profile
				</div>

				<div class="grid gap-8 lg:grid-cols-2">
					<div class="">
						<img src="@/assets/img/about-img.jpg" alt="About Us" width="1280" height="655">
					</div>
					<div class="text-justify space-y-4">
						<p>Mariale Transport Services was started on September 2, 2016 as a sole proprietorship. Throughout the years, the company gained a well-established reputation for its commitment to offer quality, timely and unparalleled customer service.</p>
						<p>Due to high-quality services we have built a solid foundation of regular customers and continue to grow and became Mariale Transport Services Corporation (MTSC) on October 28, 2020. We are also registered under PHILGEPS.</p>
						<p>MTSC offers a wide range of high-quality cars at the most affordable prices in the market from standard cars, sedans, AUV, SUV, Vans, Coaster, and Buses for daily – weekly – monthly, and yearly rentals.</p>
					</div>
					<div class="text-justify space-y-4 lg:col-span-2">
						<p>We also offer kinds of services like transfer service, car rental, shuttle services for corporate accounts, pick up and drop off services, self- driven, and chauffeur-driven services for short and long-term leasing. So let MTSC join you in this journey and provide you with an experience that for sure will be beyond your expectations.</p>
						<p>Our driving force is the passion to provide efficient and personalized customer care service to all those who engaged with us. We always strive to exceed our customer's expectations by keeping our promises and assuring them of our trust at all times.</p>
						<p>Our repeat clients, referrals, and growing customer base are testament to Customer Satisfaction. We continue to find ways to ensure that we exceed our client expectations.</p>
					</div>
				</div>
			</div>

		</section>

		<section class="section values-section odd:bg-white even:bg-gray-200">
			<div class="container py-15">
				<div class="grid gap-x-8 gap-y-12 lg:grid-cols-2">
					<div class="space-y-12">
						<div class="space-y-4">
							<div class="text-3xl font-bold heading text-siteRed-2">
								Mission
							</div>
							<div class="text-justify space-y-4">
								<p>To be competitive with high-quality services, exceeding customer expectations on our service, and to earn our customer's loyalty by working to deliver more than promised, being honest and fair.</p>
							</div>
						</div>
						<div class="space-y-4">
							<div class="text-3xl font-bold heading text-siteRed-2">
								Vision
							</div>
							<div class="text-justify space-y-4">
								<p>Our vision is to provide the highest quality service and cost-effective rent a car company in the Philippines and to offer service beyond customer satisfaction and demand.</p>
							</div>
						</div>
						<div class="space-y-4">
							<div class="text-3xl font-bold heading text-siteRed-2">
								Our Values
							</div>
							<ul class="pl-6 list-disc space-y-1">
								<li>Transparency and Integrity</li>
								<li>Focus on quality and service</li>
								<li>The great value of customer service</li>
								<li>Respect for Individual</li>
								<li>Teamwork</li>
								<li>Community Responsibility</li>
								<li>Our company is a fun and friendly place</li>
							</ul>
						</div>
					</div>
					<div class="relative overflow-hidden">
						<img class="absolute object-cover w-full h-full" src="@/assets/img/values-img.jpg" alt="MTSC Drivers">
						<div class="flex items-center h-full relative bg-[#FFF5] backdrop-blur-lg">
							<img src="@/assets/img/values-img.jpg" alt="MTSC Drivers" width="608" height="456">
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section values-section odd:bg-white even:bg-gray-200">
			<div class="container py-15">
				<div class="grid gap-x-8 gap-y-12 lg:grid-cols-2">
					<div class="space-y-12">
						<div class="text-3xl font-bold heading text-siteRed-2">
							Contact Us
						</div>
						<div class="space-y-4">
							<div class="flex gap-x-2">
								<svg class="w-7 h-7 text-siteRed-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path></svg>
								<div class="text-xl font-bold">Address</div>
							</div>
							<div class="pl-4">
								<p>924 Busilak St., Brgy. Barangka Drive, Mandaluyong City</p>
							</div>
						</div>
						<div class="space-y-4">
							<div class="flex gap-x-2">
								<svg class="w-7 h-7 text-siteRed-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"></path></svg>
								<div class="text-xl font-bold">Contact Numbers</div>
							</div>
							<ul class="pl-4 text-lg space-y-2">
								<li>
									<a class="hover:underline" href="tel:+63272183540">(02) 7218 3540</a>
								</li>
								<li>
									<a class="hover:underline" href="tel:+639173285557">0917 328 5557</a>
								</li>
								<li>
									<a class="hover:underline" href="tel:+639175625558">0917 562 5558</a>
								</li>
							</ul>
						</div>
						<div class="space-y-4">
							<div class="flex gap-x-2">
								<svg class="w-7 h-7 text-siteRed-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path></svg>
								<div class="text-xl font-bold">Email</div>
							</div>
							<ul class="pl-4 space-y-2">
								<li>
									<a class="hover:underline" href="mailto:inquiries@mtsc.com.ph">inquiries@mtsc.com.ph</a>
								</li>
							</ul>
						</div>
					</div>
					<div id="inquiry" class="space-y-12">
						<div class="text-3xl font-bold text-center heading">
							Request A Quote Now!
						</div>
						<ContactForm />
					</div>
				</div>
			</div>
		</section> -->

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
// import MenuItem from '@/components/MenuItem.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index-page',
	components: {
		SiteHeader,
		// MenuItem,
		SiteFooter,
		ContactForm,
	},
	data() {
		return {
			isOpen: 0,
			isTop: true,
			winScrollY: {
				global: 0,
				woods: 0,
			},
		}
	},
	mounted () {
		// window.addEventListener('scroll', this.handleScroll, {passive: true});
		window.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 200;
			this.winScrollY.global = window.scrollY / 1.5;
			this.winScrollY.banner = 128 + (window.scrollY / 1.5);
			this.winScrollY.woods = window.scrollY / 2;
			// this.winScrollY.about = document.getElementById("myElement").offsetTop + (window.scrollY / 1.125);
		},
	},
}
</script>

<style>
/* #mainpage {
	background-image: url('~@/assets/img/bg01.jpg');
	@apply bg-cover bg-center bg-no-repeat bg-fixed;
} */
.wood-bg {
	background-image: url('~@/assets/img/wood-bg.jpg');
	@apply bg-center;
}
.wood-bg-2 {
	background-image: url('~@/assets/img/wood-bg-2.jpg');
	@apply bg-cover bg-center bg-no-repeat;
}
.wood-bg-3 {
	background-image: url('~@/assets/img/wood-bg-2.jpg');
	@apply bg-cover bg-center bg-no-repeat;
}

.woods-slider>[id^="carousel_prev"] {
	display: block !important;
}
.parallax {
	-webkit-perspective: 1px;
	perspective: 1px;
}
.parallax-bg {
	/* @apply inset-0; */
	/* transform: translate(-50%, -50%); */
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(-1px) scale(2);
	transform: translateZ(-1px) scale(2);
	/* bottom: -200%; */
	/* background-image: url('@/assets/img/wood-bg.jpg'); */
}
.banner-section {
	/* background-image: url('~@/assets/img/buybanner.jpg');
	@apply bg-cover bg-top bg-no-repeat; */
}
@screen lg {
	.banner-section {
		/* aspect-ratio: 5/2; */
	}
}
</style>
