<template>
	<form @submit.prevent="submit">
		<div class="space-y-4 text-left text-black">

			<div class="grid gap-4">
				<div class="relative">
					<input id="name" type="text" :class="errors && errors.name ? 'border-red-500' : 'border-gray-400'" placeholder="Name"
						v-model="fields.name"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="name"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.name ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Name</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.name ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<div v-if="errors && errors.name" class="mt-1 Xmb-[10px] text-xs text-red-500">{{ errors.name[0] }}</div>
				</div>

				<div class="relative">
					<input id="contact" type="tel" :class="errors && errors.contact ? 'border-red-500' : 'border-gray-400'" placeholder="Contact No."
						v-model="fields.contact"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="contact"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.contact ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Contact No.</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.contact ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<div v-if="errors && errors.contact" class="mt-1 Xmb-[10px] text-xs text-red-500">{{ errors.contact[0] }}</div>
				</div>

				<div class="relative">
					<input id="email" type="email" :class="errors && errors.email ? 'border-red-500' : 'border-gray-400'" placeholder="Email"
						v-model="fields.email"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="email"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.email ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Email</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.email ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<div v-if="errors && errors.email" class="mt-1 Xmb-[10px] text-xs text-red-500">{{ errors.email[0] }}</div>
				</div>

				<div class="relative">
					<textarea rows="6" id="message" type="text" :class="errors && errors.message ? 'border-red-500' : 'border-gray-400'" placeholder="Message"
						v-model="fields.message"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					/>
					<label for="message"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.message ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Message</label>
					<!-- <span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.message ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span> -->
					<div v-if="errors && errors.message" class="mt-1 Xmb-[10px] text-xs text-red-500">{{ errors.message[0] }}</div>
				</div>

			</div>

			<div class="my-5">
				<div recaptcha-el></div>
				<div class="mt-1 Xmb-[10px] text-xs text-red-500">{{ recaptchaError }}</div>
			</div>

			<div class="text-center">
				<button type="submit" v-bind:disabled="busy"
					class="flex justify-center w-full px-8 py-4 bg-[#E8D716] rounded-full font-bold duration-200 hover:bg-[#EADF68] focus:outline-none mx-auto">
					<span class="px-2">
						Inquire Now!
						<svg class="inline-block w-6 h-6" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" v-if="busy">
							<defs>
								<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
									<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
									<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
									<stop stop-color="#fff" offset="100%"/>
								</linearGradient>
							</defs>
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(1 1)">
									<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</path>
									<circle fill="#fff" cx="36" cy="18" r="1">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</circle>
								</g>
							</g>
						</svg>
					</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import hapiMixins from 'hapiform-with-recaptcha';

export default {
	mixins: [hapiMixins],
	data() {
		return {
			endpoint: "https://hapiform.sg/api/5660a5ba-1040-40b1-8c71-24cd2a54a897",
			redirectTo: "/thank-you/",
			recaptchaDisabled: false,
		}
	}
}
</script>

