import Vue from 'vue'
import App from './App.vue'
import router from './router'
import OwlCarousel from 'vue-owl-carousel'
import VueGtm from '@gtm-support/vue2-gtm';
import VueSmoothScroll from 'vue2-smooth-scroll'
// import VModal from 'vue-js-modal'
import './assets/css/tailwind.css'

Vue.config.productionTip = false
Vue.component('owl-carousel', OwlCarousel)
// Vue.use(VModal)
Vue.use(VueSmoothScroll, {
	duration: 1000,
	updateHistory: false,
	// easingFunction: t => (--t)*t*t+1,
})
Vue.use(VueGtm, {
  id: ['GTM-WXQ89FW'] // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
});

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
